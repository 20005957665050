#final-page {
    /* background: linear-gradient(to right bottom, rgb(71,71,71) 50%, rgb(54, 54, 54) 50%); */
    /* background: linear-gradient(to right bottom, rgb(54,54,54) 50%, rgb(71, 71, 71) 50%); */
    color: white;
}

#final-top-pane {
    margin-bottom: 40px;
    /* background-color: darkgray; */
}

#final-body {
    padding-top: 25px;
    /* box-shadow: inset 0 7px 9px -10px black; */
}

#roll {
    z-index: 1;
    /* background-color: rgba(151, 151, 151, 0); */
    height: 50px;
}

#diagonal-background, #d20 {
    cursor: pointer;
}

#diagonal-background {
    z-index: -1;
    position: absolute;
    /* background-color: red; */
    border-style: solid;
    width: 0px;
    height: 0px;
    border-width: 45px 0 45px 50px;
    border-color: rgb(182, 182, 182) transparent transparent rgb(182, 182, 182);
    /* box-shadow: black; */
}

#roll:hover {
    cursor: pointer;
}

#diagonal-background.hovering-roll-border {
    border-color: rgb(219, 83, 20) transparent transparent rgb(219, 83, 20);
}

#diagonal-background.click-roll {
    border-color: rgb(172, 64, 15) transparent transparent rgb(172, 64, 15);
}

#d20 {
    /* z-index: 1; */
    padding: 10px 4px 0px 6px;
    height: 50px;
    width: 50px;
    /* background-color: rgba(182, 182, 182, 0); */
    border-radius: 0 0 3px 0;
    margin-top: -5px;
    /* margin-bottom: 15px; */
}

#line-1 {
    height: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: 50px auto;

    /* vertical-align: middle; */
}

#line-1 > * {
    vertical-align: middle;
}

#search {
    margin: 10px 10px 0px;
    margin-right: 3%;
    height: 35px;
    /* width: 100%; */
    display: inline-grid;
    grid-template-columns: auto 50px;
}

#search > * {
    /* display: inline; */
}

#search input {
    background-color: rgb(177, 177, 177);
    height: 35px;
    font-size: 1.25em;
    text-align: right;
    padding: 6px 6px 2px;
    /* width: 239px; */
    justify-self: end;
    /* margin-right: 4px; */
    border: solid 1px gray;
    font-weight: bold;
    width: 100%;
    max-width: 245px;
    /* background-image: url('../../ImageStore/magnifyingglass.jpg'); */
    /* background-image: url('../../ImageStore/magnifyingglass.png'); */
    /* position: left; */
    /* background-size: 40px; */
    /* background-repeat: no-repeat; */
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

#reset-filters {
    /* margin-left: 10px; */
    line-height: 35px;
    vertical-align: middle;
    text-align: center;
    text-decoration: underline;
    /* border-top: solid 1px black; */
    /* border-right: solid 1px black; */
}

/* #reset-filters:hover {
    color: rgba(255, 255, 255, 0.733);
    cursor: pointer;
}

#reset-filters:active {
    color: rgba(199, 199, 199, 0.295);
} */

#filters {
    z-index: 2;
    /* margin-top: 10px; */
    /* margin-right: 10px; */
    display: inline-grid;
    grid-template-columns: 36px auto auto auto;
    /* margin-left: 60px; */
    /* width: 300px; */
    /* justify-self: end; */
    /* width: 80%; */
    /* height:  */
    /* vertical-align: middle; */
    position: absolute;
    justify-content: end;
    right: 3%;
    height: 35px;
}

#filters > * {
    margin: 3px;
    padding: 0px 2px;
    font-size: 15px;
    /* text-align: center; */
}

#filters > * {
    cursor: pointer;
}

#filters button {
    height: 30px;
    background-color: white;
    outline: none;
    /* border: solid 1px gray; */
    border: none;
    border-radius: 1px;
    background-color: rgb(100, 100, 100);
    /* color: white; */
}

body #filters .selected {
    background-color: rgb(179, 2, 2);
}

body #filters > .selected:hover {
    background-color: rgba(179, 2, 2, 0.87);
}

/* body #filters > button:hover {
    background-color: rgba(199, 199, 199, 0.767);
} */

body #filters > .selected:active {
    background-color: rgb(160, 1, 1);
}

body #filters > button:active {
    background-color: rgb(75, 75, 75);
}

#filters #type-filter {
    width: 70px;
    text-align: center;
    background-color: rgba(59, 59, 59, 0);
}

#filters #type-filter:hover {
    /* background-color: rgb(136, 136, 136) */
}

#filters #active-filter {
    width: 63px;
    background-color: rgba(92, 92, 92, 0);
}

#filters #active-filter:hover {
    /* background-color: rgb(121, 121, 121); */
}

#filters #rolesource-filter {
    width: 110px;
    background-color: rgba(83, 83, 83, 0);
}

#filters #rolesource-filter:hover {
    /* background-color: rgb(124, 124, 124); */
}

#filters .filter-button:hover {
    border: solid 1px rgba(255, 255, 255, 0.349);
}

#filter-star {
    filter: contrast(120%)
}

#filter-star-gray {
    filter: contrast(85%)
}

#filters > .top-star {
    height: 24px;
    width: 30px;
    margin: 0 auto;
    margin-top: 5px;
}

#filters > .top-star:hover {
    filter: contrast(150%)
}

#filters > .top-star:active {
    filter: contrast(70%)
}

#skills-header {
    margin-left: 3%;
    margin-bottom: -5px;
}

#skills-header > * {
    display: inline-block;
}

#manage-skill-choices {
    /* border: solid 1px black; */
    /* border-right: solid 1px black; */
    /* border-left: solid 1px black; */
    /* width: 169px; */
    color: white;
    padding-left: 5px;
    text-decoration: underline;
    /* position: absolute; */
    /* right: 5px; */
}

.filter-alive {
    background-color: rgb(209, 100, 28) !important;
    color: white !important;
}

#manage-skill-choices {
    /* cursor: pointer; */
}

#empty-skills-body {
    margin: 60px 30px 150px;
    padding: 20px;
    border: solid 1px black;
    text-align: center;
}

.element-list {
    color: rgb(26, 102, 52);
    font-weight: bold;
    /* font-style: italic; */
}

#toggle-all-open-closed {
    float: right;
    margin-right: 3.25%;
    margin-top: 8px;
    text-decoration: underline;
    cursor: pointer;
}

#toggle-all-open-closed, #reset-filters, #manage-skill-choices, .filter-button {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.733);
}

#toggle-all-open-closed:hover, #reset-filters:hover, #manage-skill-choices:hover, .filter-button:hover {
    color: white;
}

#toggle-all-open-closed:active, #reset-filters:active, #manage-skill-choices:active {
    color: rgba(199, 199, 199, 0.726);
}

@media all and (min-width: 400px) { 
    #final-top-pane {
        margin-bottom: 40px;
    }
}

@media all and (min-width: 510px) { 

    #final-top-pane {
        margin-bottom: 30px;
    }
    
    #search {
        margin-top: 12px;
    }
    
    #filters {
        margin-top: 5px;
    }

    #filters button {
        height: 30px;
    }

    #search input {
        max-width: 343px;
    }

    #filters > button {
        /* width:600px; */
        margin-left: 7px;
        width: 110px !important;
    }
}

@media all and (min-width: 640px) { 
    
    #final-skills-list > .one-skill:nth-child(2n+0) {
        /* background-color: gray; */
        /* width: 50%; */
        /* order: 1; */
    }


    #final-skills-list {
        margin-top: 10px;
        display: grid;
        width: 94%;
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
        margin-right: 3%;
        margin-left: 3%;
    }

    #final-skills-list .one-skill {
        margin: 0;
    }

    .open-skill {
        grid-area: span 1/span 2
    }

    #empty-skills-body {
        grid-area: span 1/span 2;
    }
}
