
/* #character-pane { */
    /* background-color: rgb(83, 83, 83) */
/* } */

#character-pane {
    background-color: rgb(101, 167, 121);
    /* padding: 6px; */
    /* display: grid; */
    /* grid-template-columns: 65px auto auto 65px; */
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0px 0px 2px 0px;
}

#character-pane > * {
    text-align: center;
    /* vertical-align: middle; */
    /* margin: 6px; */
    /* margin-top: 6px;
    margin-bottom: 6px; */
}

#character-title-pane {
    display: grid;
    grid-template-columns: 65px auto 65px;
    background-color: rgb(209, 100, 28)
}

#character-editing-pane .non-select {
    background-color: rgba(110, 110, 110, 0.158);
}

#character-editing-pane .non-select * {
    color: rgba(255, 255, 255, 0.356);
}

#name-and-title {
    margin: 5px;
}

#source-role:hover, #name-and-title:hover {
    cursor:pointer;
    background-color: rgba(255, 255, 255, 0.13);
    border-radius: 4px;
}

#source-role:active, #name-and-title:active {
    background-color: rgba(255, 255, 255, 0.226);
}

#source-role {
    height: 60px;
    margin: 6px;
    /* text-align: center; */
}

.each-source-role {
    height: 50%;
    display: table;
    text-align: center;
    width: 100%;
}

.each-source-role > * {
    display: table-cell;
    vertical-align: middle;
    /* width: 100px; */
    /* width: 100%; */
    /* text-align: center; */

    /* margin: 0 auto; */
}

#titles {
    display: none;
}

#titles a {
    /* height: 100%; */
}

.each-source-role {
    /* height: 50%;
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    margin: 0 auto;
    text-align: center; */
    /* line-height: 18px; */
}


#source-role > * {
    /* #titles { */
        /* display: table; */
        /* width: 100%; */
        /* Width of parent */
        /* height: 50%; */
        /* Height of parent */
        /* overflow: hidden; */
    /* height: 50%; */
    /* line-height: 18px; */
}

#source-role > * {
    /* display: table-cell;
    vertical-align: middle;
    width: 100%;
    margin: 0 auto;
    text-align: center; */
}

#source-role {

    /* line-height: 18px; */
}

#name {
    line-height: 40px;
    font-size: 1.1em;
    /* margin-left: 5px; */
}

#name.big-name {
    line-height: 62px;
}

#hero-title {
    /* display: none; */
}

.character-pane-box {
    line-height: 70px;
    vertical-align: middle;
    /* border-radius: 6px; */
}

#overstep-box label {
    letter-spacing: -1px;
}

#overstep-box {
    line-height: 24px;
    /* border: solid 2px black; */
    /* border-radius: 6px; */
    /* background-color: rgba(255, 255, 255, 0.349); */
    margin: 4px 9px 7px 4px;
    padding: 3px 0px;
    cursor: pointer;
    /* max-width: 130px; */
}

#overstep-box:hover {
    background-color: rgba(255, 255, 255, 0.164);
}

#overstep-box:active {
    background-color: rgba(75, 75, 75, 0.151);
}

#overstep-check {
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
    width: 18px;
    height: 18px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.466);
    outline: solid 2px rgb(41, 41, 41);
    margin-top: 3px;
    margin-bottom: -3px;
}

#overstep-check:checked {
    background-color: rgba(0, 0, 0, 0);
    outline: 0;
    width: 10px;
    height: 20px;
    border: solid black;
    margin-top: -3px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.character-pane-box:hover {
    /* background-color: rgba(255, 255, 255, 0.116); */
    /* cursor: pointer; */
}

.character-pane-box:active {
    /* background-color: rgba(255, 255, 255, 0.171);    */
}

#hp-box, #damage-box {
    /* padding-top: 4px; */
    /* border-radius: 6px; */
    /* margin-top: 4px; */
    /* margin-bottom: 4px; */
}

#hp-line > * {
    display: inline-block;
}

#damage-box {
    /* line-height: 28px;
    padding-top: 4px;
    border-radius: 6px;
    margin-top: 4px;
    margin-bottom: 4px; */
}

/* #overstep-check {
    -webkit-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
-moz-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
} */

.character-pane-box > * {
    padding: 1px;
}

#character-tracking-pane {
    display: grid;
    grid-template-columns: auto auto 65px;
    height: 72px;    
}

#tracking-notes-button {
    display: inline-grid;
    grid-template-columns: auto auto auto;
    margin: 3px 10px 5px -4px;
    /* border-radius: 7px; */
    padding: 5px 5px 3px;
    /* background-color: rgba(0, 0, 0, 0.13); */
    /* line-height: 28px; */
    box-shadow: 0px 0px 2px rgb(41, 41, 41);

    border: solid 1px black;
    cursor: pointer;
}

#tracking-notes-button:hover {
    background-color: rgba(255, 255, 255, 0.212);
}

#tracking-notes-button > * {
    line-height: 24px;
}

#tracking-notes {
    line-height: 0px;
    /* vertical-align: middle; */
}

#hp-box, #damage-box {
    margin-top: 2px;
}

#notepad {
    width: 36px;
    /* height: 48px; */
    margin: 2px 8px 3px 0;
    /* margin-left: 3px; */
    /* border-radius: 8px; */
    /* padding: 4.5px; */
}

#notepad:hover {
    /* background-color: rgba(216, 216, 216, 0.548); */
    /* cursor: pointer; */
}

#notepad:active {
    /* background-color: rgba(216, 216, 216, 0.267); */
}

#flip-arrow {
    height: 50px;
    /* line-height: 36px; */
    vertical-align: middle;
    /* padding-left: 6px; */
    /* border-left: solid 2px black; */
    /* color: white; */
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition:         transform .2s ease-in-out;
    filter: invert(100%);
}

#flip-arrow:hover {
    -webkit-transform: rotate(-40deg);
    transform: rotate(-40deg);
}

#flip-arrow:active {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

#arrow-box {
    /* padding-top: 5px;
    padding-bottom: 5px; */
    padding-left: 6px;
    padding-right: 6px;
    /* border-left: solid 2px black; */
    /* padding-top: 11px; */
    /* padding-bottom: 7px; */
    margin: 0px;
    margin-right: -1px;
    line-height: 20px;
    background-color: rgb(68, 68, 68);
    /* background-color: rgba(128, 128, 128, 0); */
    color: white;
}

#arrow-box:hover {
    cursor: pointer;
    background-color: rgb(73, 73, 73);
}

#arrow-box:active {
    background-color: rgb(116, 116, 116);
}

#level-box {
    padding: 6px;
    margin: 6px;
    width: 60px;
    height: 60px;
    line-height: 20px;
    border-radius: 4px;
    border: solid 2px black;
    background-color: rgb(228, 228, 228);
}

#level-box:hover,#level-box *:hover {
    cursor: pointer;
    background-color: white;
}

#level-box:active,#level-box *:active {
    /* color: white; */
    background-color: rgb(163, 163, 163);
} 

#level-box > * {
    /* padding: 1px; */
}

#character-editing-pane {
    display: inline-block;
    vertical-align: middle;
}

#character-editing-pane {
    color: white;
    display: inline-grid;
    grid-template-columns: 60% 40%;
    width: 100%;
    line-height: 60px;
    vertical-align: middle;
}

#character-editing-pane > *{
    margin: 5px;
}

#character-editing-pane .button {
    /* margin: 5px; */
    padding: 0px;
    border: solid 1px rgba(255, 255, 255, 0.308);
    border-radius: 4px;
    cursor: pointer;
    color: white;
}

#character-editing-pane .vertical-stacked {
    font-size: .8em;
    line-height: 26px;
    /* margin: 5px; */
}

#character-editing-pane .vertical-stacked .button {
    padding-top: 3px;
    /* margin-bottom: -6px; */
}

#character-editing-pane .vertical-stacked .button:nth-child(1) {
    margin-bottom: 3px;
}

#character-editing-pane .button:hover {
    background-color: rgba(255, 255, 255, 0.034);
    border: solid 1px rgba(255, 255, 255, 0.425);
}

#character-editing-pane .button:active {
    background-color: rgba(255, 255, 255, 0.212);
}


#character-pane-skills-numbers {
    transition: .5s all ease;
    color: white;
    text-align: left;
    /* vertical-align: middle; */
    line-height: 21px;
    /* margin-left: 40px; */
    margin: 6px 0 2px 20px;
}

#dice-box {
    /* text-align:center; */
    /* line-height: 70px; */
    /* vertical-align: middle; */
    /* padding-right: 20px; */
    /* margin-right: px; */
}

#dice {
    /* vertical-align: middle; */
    /* margin: 0 auto; */
    height: 60px;
    width: 60px;
    line-height: 70px;
    vertical-align: middle;
    margin-left: 2px;
    /* line-height: 72px; */
    /* top: 50%; */
    /* line-height: 72px; */
    /* display: inline-block; */
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition:         transform .2s ease-in-out;
}

#dice:hover {
    /* cursor: pointer; */
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
}

#dice:active {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

@media all and (min-width: 600px) { 

    #character-title-pane {
        grid-template-columns: 65px auto auto 65px;
    }

    #titles {
        display: block;
    }

    #name-and-title {
        margin-left: 0px;
        /* line-height: 40px; */
    }

    #hero-title {
        /* display: block; */
    }
    
}

@media all and (min-width: 450px) { 
    #name {
        font-size: 1.6em
    }
}