.intersection-page {
    color: white;
}

#intersection-header {
    text-align: center;
    max-width: 500px;
    margin: 15px auto;
}

#intersection-peek-box {
    margin: 3%;
}

#intersection-peek-bar {
    text-align: center;
    border: solid 2px white;
    padding: 3px;
    margin-bottom: 10px;
}

.intersection-section {
    padding: 0px 12px 20px;
    margin: 10px auto;
    max-width: 600px;
    border: solid 1px rgba(255, 255, 255, 0.158);
    border-radius: 10px;
    width: 90%;
    background-color: rgb(36, 36, 36);
}

.gray {
    background-color: rgb(99, 99, 99);
    color: white;
    border-bottom: solid 2px rgb(27, 27, 27);
}

#intersection-peek-bar.whited {
    background-color: rgb(99, 99, 99);
}

.gray:hover, .whited:hover,
.gray:active, .whited:active {
    margin-bottom: 0px;
    border-radius: 2px 2px 0px 0px;
}

.gray:active, .whited:active {
    /* background-color: black !important; */
    color:white;
}

#intersection-peek-bar.gray:hover, #intersection-peek-bar.whited:hover {
    background-color: rgb(56, 56, 56);
}


.gray:hover,.whited:hover,.gray:active,.whited:active, 
.gray *:hover,.whited *:hover,.gray *:active,.whited *:active {
    cursor:pointer;
}

#intersection-headline {
    margin: 10px 30px 20px;
}

.intersection-page {
    /* margin: 15px; */
}

.intersection-path-header {
    text-align: center;
    margin-top: 20px;

}

.intersection-item-content {
    background-color: rgb(24, 23, 23);
    border-radius: 10px;
    padding: 12px;
    text-align: center;
    border: solid 2px rgba(255, 166, 0, 0.048);
    /* height: 100%; */
}

.intersection-item-content:hover {
    background-color: rgb(31, 28, 28);
    cursor: pointer;
}

.intersection-item-content.selected-intersection:hover {
    background-color: rgb(100, 77, 61);
    cursor: pointer;
}

.skill-description-part {
    padding: 4px;
}

#intersection-content .item-name {
    text-align: center;
}

.non-select {
    pointer-events: none;
}

.non-select .intersection-item-content {
    opacity: 50%;
    pointer-events: none;
}

.intersection-section .intersection-item-content.intersection-selected {
    background-color: gray;
}

.edit-skills-checkbox:checked {
    /* height: 100px; */
    /* background-color: white; */
}

.selected-intersection {
    background-color: rgb(83, 63, 49);
    border: solid 2px orange;
}

@media all and (min-width: 550px) { 
     .intersection-section-inline {

        /* display:inline-block; */

         display: grid;
         /* overflow: hidden; */
         grid-template-columns: 48.5% 48.5%;
         grid-auto-rows: 1fr;
         grid-column-gap: 3%;
     }
     .intersection-section-inline > * {
         display: flex;
     }

     .intersection-item-content {
        height: 98%;
    }

     .intersection-box-label {
         /* height: 100%;   */
     }

     .intersection-box-label > * {
         /* height: 100%; */
     }
}