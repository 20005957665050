
.bh-choice {
    margin: 10px;
    text-align: center;
}

.bh-choice h4 {
    font-size: 1.2em;
}

.bh-radio {
    display: none;
}

.bh-choice h3 {
    margin: 3px;
}

.bh-choice p {
    margin: 8px;
}

.bh-options > * {
    /* border: solid 2px white; */
}

p.bh-name-inputs {
    margin-top: 15px;
}

input.bh-name-inputs {
    padding: 5px 0px;
    text-align: center;
    font-size: 20px;
    width: 120px;
    color: white;
    background-color: rgba(255, 255, 255, 0.11);
}

input.bh-name-inputs:focus {
    color: black;
    background-color: rgb(185, 185, 185);
}

.bh-choice .decision-button {
    padding: 7px 5px 5px;
}

.one-bounty-skill {
    /* background-color: rgb(70, 70, 70); */
    border: solid 1px white;
    border-radius: 5px;
    padding: 5px 3px 3px;
    margin: 4px;
    /* max-width: 200px; */
}

@media all and (min-width: 640px) { 


}