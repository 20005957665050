
.role-source-popup-details h1 {
    /* background-color: green; */
    /* background: linear-gradient(180deg, rgba(0, 255, 115, 0.8), rgba(0, 255, 98, 0) 100%); */
    color: white;
    /* background-color: black; */
    /* text-shadow: 0 0 5px rgb(92, 92, 92); */
    padding-bottom: 7px;
    margin-bottom: 0px;
}

.scrollable-content {
    /* overflow-y: scroll; */
    /* max-height: 170px; */
    /* background-color: rgba(41, 41, 41, 0.192); */
    padding-top: 25px;
    padding-bottom: 10px;
}

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f100; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(64, 187, 125); 
    /* cursor: pointer; */
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(142, 207, 175); 
  }

.scrollable-content > * {
    padding: 5px 20px 10px;
}

.rolesource-trait-section {
    /* border: solid 1px white; */
    border-radius: 5px;
    border-bottom: none;
    padding: 15px 4px 1px;
    margin: 8px 8px 0px;
    /* margin-bottom: none; */
    background-color: rgb(29, 29, 29);
    /* max-height: 250px; */
    /* padding: px 0px; */
    margin-bottom: 40px;
}

.rolesource-given-skills-tag {
    /* font-size: 1.15em; */
    margin-bottom: 14px;
    text-align: center;
    /* text-align: left; */
    /* text-decoration: underline; */
}

.trait-block {
    font-size: 1.15em;
    border: solid 1px #888;
    background-color: rgba(0, 0, 0, 0.808); /* Black w/ opacity */
    border-radius: 5px;
    margin: 0 0 7px;
    /* padding: 5px; */
}

.trait-block-button {
    font-size: 14px;
    display: inline;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
}

.trait-block-button:hover {
    background-color: rgba(26, 26, 26, 0.808); /* Black w/ opacity */
}

.trait-block-button:active {
    background-color: rgba(8, 8, 8, 0.808); /* Black w/ opacity */
}

.trait-description {
    /* overflow-y: scroll; */
    /* max-height: 170px; */
    /* background-color: rgba(41, 41, 41, 0.192); */
}

.accept-or-decline {
    /* text-align: center; */
}

.accept-or-decline button {
    font-size: 18px;
    width: 100%;
    /* height: 30px; */
    border: solid 1px rgba(255, 255, 255, 0.596);
    border-top: none;
    outline: none;

    display: inline-block;
    padding: 10px 15px;
    line-height: 15px;
    /* font-size: 20px; */
    border-radius: 0;
    -webkit-appearance: none;
    /* height: 100%; */
    color: white;
}

#decline-rolesource-choice {
    background-color: rgb(202, 41, 41);
    cursor: pointer;
}

#decline-rolesource-choice:hover {
    background-color: rgb(214, 64, 64);
}

#decline-rolesource-choice:active {
    background-color: rgb(170, 30, 30);
}

#accept-rolesource-choice {
    background-color: rgb(3, 148, 76);
    cursor: pointer;
}

#accept-rolesource-choice:hover {
    background-color: rgb(25, 177, 101);
}

#accept-rolesource-choice:active {
    background-color: rgb(2, 117, 59);
}

.choice-skill-nav {
    list-style: none;
}

.choice-skill-nav * {
    color: white;
}

.role-source-overlay {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.801), rgba(8, 8, 8, 0) 4px);
    pointer-events: none;
    /* top: 0px; */
    /* left: 0px; */
    width: 100%;
    /* background-color: white; */
    height: 60vh;
}

.one-trait {
    border-left: solid 3px rgb(100, 173, 122);
    margin: 0px 10px 25px;
    /* padding-bottom: 1px; */
}

.one-trait > * {
    margin-left: 10px;
}

.one-trait h2 {
    font-size: 1.1em;
    padding-top: 3px;
    padding-bottom: 2px;
}

.trait-description-part {
    margin: 8px 0px;
}

.accept-or-decline .non-select {
    cursor: default !important;
    background-color: rgb(92, 91, 91) !important;
    color: gray;
}

.playing-as {
    background-color: rgb(29, 29, 29);
    border-radius: 5px;
    padding: 15px 8px 10px;
    margin: 8px;
    margin-bottom: 17px;
}

.playing-as h3 {
    /* margin-bottom: 10px; */
    padding: 8px;
    /* text-align: center; */
}

.playing-as-content {
    padding: 8px;
}


/* COMMON DECISION CSS BELOW*/

.rolesource-trait-section .selected-decision-trait {
    background-color: rgb(185, 90, 26);
    /* border: solid 2px orangered */
}

.rolesource-trait-section .selected-decision-trait:hover {
    background-color:rgb(185, 90, 26);
    cursor: default;
    /* border: solid 2px orangered */
}


.decision-button {
    /* border: solid 2px white; */
    background-color: rgb(48, 48, 48);
    padding: 10px 5px 5px;
    border-radius: 7px;
    margin: 6px;
    cursor: pointer;
}

.decision-button:hover {
    background-color: rgb(61, 61, 61);
}