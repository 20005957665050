
#roll-page .gray {
    background-color: rgb(114, 114, 114) !important;
}

#roll-page .gray:hover {
    background-color: rgb(145, 145, 145) !important;
}

#roll-page .gray:active {
    background-color: rgb(88, 88, 88) !important;
}

#upper-hand-column > #upper-hand-switch,
#affect-roll .affect-button,
#clear-roll-row .roll-that-dice,
#clear-roll {
    background-color: rgb(101, 167, 121);
    border-bottom: solid 2px rgb(27, 27, 27);
    outline: none;
    -webkit-touch-callout: none;
      -webkit-user-select: none; 
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}

#upper-hand-column > #upper-hand-switch:hover, 
#affect-roll .affect-button:hover, 
.roll-that-dice:hover,
#clear-roll-row *:hover {
    background-color: rgb(112, 175, 131);
    cursor: pointer;
}

#upper-hand-column > #upper-hand-switch:active,
#affect-roll .affect-button:active, 
.roll-that-dice:active,
#clear-roll-row *:active {
    background-color: rgb(89, 155, 109);
}

#affect-roll #weaken-button {
    background-color: rgb(170, 51, 51);
}

#affect-roll #weaken-button:hover {
    background-color: rgb(184, 63, 63);
}

#affect-roll #weaken-button:active {
    background-color: rgb(145, 39, 39);
}

#clear-roll-row .roll-that-dice#the-clear-button {
    background-color: rgb(161, 161, 95);
}

#clear-roll-row .roll-that-dice#the-clear-button:hover {
    background-color: rgb(182, 182, 54);
}

#clear-roll-row .roll-that-dice#the-clear-button:active {
    background-color: rgb(160, 160, 17);
}

#roll-page {
    background-color: rgb(56, 56, 56);
    padding: 7px 0px;
    width: 100%;
    position: absolute;
}

#roll-area {
    background-color: white;
    margin: 20px;
}

#dice-area {
    padding-left: 4px;
    margin: 0 auto;
    width: 340px;
    display: grid;
    grid-template-columns: 30% 47%;
    margin-bottom: -10px;
}

#dice-area.double-dice-layout {
    grid-template-columns: 30% 35% 35%;
}

#upper-hand-column {
    height: 90%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50%;
    padding-bottom: 3%;
}
    

#upper-hand-column > * {
    color: white;
    height: 80%;
    text-align: center;
    /* padding: 4px; */
    margin: 10%;
    /* vertical */
}

#upper-hand-column > #upper-hand-switch {
    border-radius: 10px;
    padding: 14px;
    height: 60px;
    /* margin: 10px 0px; */
}


#prior-roll {
    /* margin-bottom: 15px; */
    padding: 8px 6px 0px;
    border-radius: 10px;
    cursor: default;
    height: 69px;
    color: rgb(255, 255, 255);
    /* border-right: solid 1px white; */
}

#prior-roll-number {
    margin-bottom: -10px;
    padding-bottom: 0px;
}

.prior-roll:hover {
    /* background-color: rgb(87, 87, 87); */
    /* color: white !important; */
    text-decoration: underline;
    cursor: pointer !important;
}

.prior-roll:active {
    /* background-color: rgb(102, 102, 102); */
}

#prior-roll-head {
    /* text-decoration: underline; */
}

#upper-hand-column #prior-roll-number {
    font-size: 30px;
}

#upper-hand-column #prior-roll-result {
    font-size: .95em;
    margin-top: 10px;
    /* padding: 10px; */
}
/* 
#upper-hand-button {
    padding: 4px;
    border-radius: 4px;
} */

.one-dice-section {
    display: inline-block;
    margin: 0 auto;
    /* margin-bottom: -10px; */
    margin-top: 15px;
    /* width: 340px; */
    /* display: block; */
    text-align: center;
    color: white;
}

#dice-1-section {
    margin-right: 5px;
}

#dice-2-section {
    margin-left: -10px;
    margin-top: 25px;
}

#dice-2-section .action-dice {
    width: 75px;
}

#dice-2-section .action-roll-result {
    font-size: 30px;
}

.action-dice {
    /* margin: 0 auto; */
    /* background-color: white; */
    /* margin-bottom: 20px; */
    /* display: block; */
    width: 100px;
    padding-left: 15px; 
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    filter: invert(80%);
    margin-bottom: -10px;
}

.chosen-roll {
    font-weight: bold;
    /* text-shadow: 10x 10px white; */
}


#dice-2-section .action-dice {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    margin-left: 0px;
}

.action-dice:hover {
    -webkit-transform: rotate(40deg) !important;
    transform: rotate(40deg) !important;
}

.action-dice:active {
    -webkit-transform: rotate(-150deg) !important;
    transform: rotate(-150deg) !important;
    /* transform: translateY(50px); */
}

.action-dice-activate {
    -webkit-transform: rotate(-150deg) !important;
    transform: rotate(-150deg) !important;
}

.action-roll-result {
    margin: 0 auto;
    font-size: 40px;
    width: 100px;
    margin-top: 8px;
    margin-bottom: 5px;
    margin-left: 9px;
}

.green-success, .red-success {
    text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.274);
}

.green-success {
    color: rgb(127, 214, 153);
    /* text-shadow: 0px 0px 20px rgb(0, 112, 50); */
}

.red-success {
    color: rgb(236, 81, 81);
    /* text-shadow: 0px 0px 20px red; */
}

#action-description-container {
    background-image: linear-gradient( rgb(56, 56, 56),rgb(70, 70, 70) );
    border-radius: 10px;
    padding: 10px 10px 20px;
    margin: 0 2.5%;
}

#action-description {
    display: inline-grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    color: rgb(187, 187, 187);
}

.remove-sides {
    /* display: none */
}


#affect-roll {
    text-align: center;
    font-size: 1.1em;
    vertical-align: middle;
    line-height: 21px;
    /* width: 150px; */
    /* margin: 0 auto; */
}

#strengthened-weakened {
    font-size: .95em;
    color: white;
}

#affect-roll > * {
    width: 150px;
    margin: 0 auto;
}

#affect-roll .affect-button {
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 120px;
    padding: 2px;
    border-radius: 6px;
    /* border: solid 1px black; */
}

#strengthen-word {
    padding-bottom: 2px;
}

#strengthened-weakened {
    padding-top: 2px;
}

#weaken-word {
    padding-top: 2px;
}

/* slider itself */
/* #action-level-slider {
    writing-mode: bt-lr;
    -webkit-appearance: slider-vertical;
    -webkit-appearance: none;
    width: 34px;
    height: 150px;
    margin-top: 4px;
} */
 
#action-level-slider {
    -webkit-transform:rotate(90deg);
    -moz-transform:rotate(90deg);
    -o-transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    transform:rotate(90deg);
    /* height: 100px; */
    -webkit-appearance: none;
    height: 2px;
    width: 162px;
    position: relative;
    right: 65px;
    top: 77px;
    /* opacity: .5; */
    background-color: rgba(32, 32, 32, 0.158);
}

  /* Mouse-over effects */
  #action-level-slider:hover {
    /* opacity: 1; Fully shown on mouse-over */
  }
  
  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  #action-level-slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: none;
      height: 22px;
      width: 22px;
      background-size: 21px 12.5px;
      /* border-radius: 50%; */
      background-color: transparent;
      background-image: url(../../ImageStore/chevron.png);
      background-position: center center;
      background-repeat: no-repeat;
      filter: invert(100%);

    /* Override the default look */
    /* -webkit-appearance: none; */
    /* appearance: none; */
    /* width: 20px; */
    /* height: 10px; */

    /* background-image: url(../../ImageStore/dice.png); */

    /* background: white; */
    /* border-radius: 5px; */
    /* -webkit-border-radius: 5px; */
   /* -moz-border-radius: 5px; */
   /* -o-border-radius: 5px; */
   /* box-shadow: 0px 0px 2px white; */

   /* border: solid 2px black; */
    /* cursor: pointer; Cursor on hover */
  }
  
  #action-level-slider::-moz-range-thumb {
    width: 20px; /* Set a specific slider handle width */
    height: 8px; /* Slider handle height */
    /* background: #4CAF50; Green background */
    /* cursor: pointer; Cursor on hover */
  }

#action-level {
    display: inline-grid;
    /* grid-template-columns: 25px auto 36px; */
    grid-template-columns: auto 34px auto auto ;
    /* width: 377px; */
    line-height: 23.14px;
}

.highlight-success {
    color: white;
    /* font-weight: bold; */
    text-shadow: 0px 0px 8px rgb(255, 255, 255);
}

.highlight-success-strong {
    color: white;
    text-shadow: 0px 0px 8px rgb(0, 236, 126);
}

.highlight-success-weak {
    color: white;
    text-shadow: 0px 0px 8px rgb(255, 0, 0);
}

#action-level-names {
    width: 125px;
}

#clear-roll-row {
    text-align: center;
}

#clear-roll-row > * {
    display: inline;
}

#clear-roll {
    margin-right: 20px;
    /* margin-top: 3px; */
    font-size: 25px;
    /* margin-bottom: 10px; */
    padding: 10px;
    color: white;
    border-radius: 12px;
}

.roll-that-dice {
    width: 170px;
    height: 45px;
    /* display: block; */
    /* border: solid 1px white; */
    border: none;
    border-radius: 12px;
    margin: 15px auto 0px;
    color: white;
    font-size: 25px;
}

.disable {
    pointer-events: none !important;
    background-color: gray !important;
}

#roll-stats-button {
    text-align: center;
    height: 30px;
    width: 30px;
    background-color: rgb(95, 77, 255);
    color: white;
    border-radius: 15px;
    padding: 8px 11px 3px;
    /* color: white; */
    position: relative;
    right: 0px;
    bottom: 20px;
    float: right;
    /* position: absolute; */
    display: flex;
    cursor: pointer;
}

#bottom-fill {
    color: white;
    position: absolute;
    bottom: 0px;
}

@media all and (min-width: 475px) { 

    #dice-area {
        width: 430px;
        height: 173px;
    }

    #action-description-container, #action-breakdown {
        width: 460.75px;
        display: block;
        margin: 0 auto;
    }

    #action-description {
        width: 460.75px;
        margin: auto;
        /* display: flex; */
    }

    /* #roll-stats-button {
        left: -82px !important;
    } */
}

@media all and (min-width: 400px) { 

    .remove-sides {
        /* display: unset; */
    }
    
    #action-description{
        /* grid-template-columns: 2% 48% 48% 2%; */
    }

    #affect-roll .affect-button {
        width: 150px;
    }
    #action-dice {
        width: 200px;
    }

    /* #roll-stats-button {
        left: -50px;
    } */

}