
.demonic-choice {
    margin: 10px;
    text-align: center;
}

.demonic-choice h4 {
    font-size: 1.2em;
}

.demonic-choice input {
    display: none;
}

.demonic-choice h3 {
    margin: 3px;
}

.demonic-choice p {
    margin: 8px;
}

.demon-options > * {
    /* border: solid 2px white; */
}

@media all and (min-width: 640px) { 


}