.plea-section {
    margin: 12px 0 0;
}

.plea-name {
    font-weight: bold;
}

.plea-action {

}

.orange {
    color: rgb(255, 103, 2)
}

.plea-edit {
    margin-left: 16px;
}