.one-skill {
    margin: 10px 3%;
    /* border: solid 1px black; */
}

.one-skill:hover {
    /* background-color: rgb(155, 155, 155); */
    /* cursor: pointer; */
}

#one-skill > * {
    /* display: inline-grid; */
}

.skill-head-pane {
    /* display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between; */
    /* DON'T TOUCH ABOVE, it's for centering purposes */
    padding: 12px 10px 8px 10px;
    background-color: white;
    color: black;
    border: solid 1px rgba(128, 128, 128, 0.151);
    /* border-bottom: 0px; */
    height: 65px;
    /* box-shadow: 2px 2px 2px 0px; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.skill-header-open {
    color: white;
    background-color: rgba(0, 0, 0, 0.39);
    /* background-color: rgb(163, 151, 113); */
}

#final-body .one-skill:nth-child(odd) .skill-header-open {
    /* background-color: rgb(146, 125, 95); */
}

.skill-head-pane {
    cursor: pointer !important;
}

.skill-head-pane-top {
    display: flex;
    justify-content: space-between;
}

.skill-head-pane:hover {
    /* border: solid 1px black; */
    background-color: rgba(0, 0, 0, 0.212);
    border: solid 1px rgba(255, 255, 255, 0.788);
}

.skill-head-pane:active {
    background-color: rgba(0, 0, 0, 0.432);
}

.skill-pane-info {
    display: flex;
    justify-content: space-between;
}

.skill-head-pane h3 {
    font-size: 1.2em;
    /* padding-bottom: 5px; */
}

.item-gray-star {
    filter: brightness(45%)
}

.item-gold-star, .item-gray-star {
    height: 17px;
    margin-top: -3px;
}

.skill-dropdown {
    color: black;
    padding: 7px;
    font-size: 14px;
    border: solid 1px rgb(128, 128, 128);
    border-top: none;
    background-color: rgb(219, 219, 219);
}

.skill-dropdown p {
    padding: 3px;
}

.one-skill .red {
    color: rgb(226, 166, 55);
}