
#editing-main {
    width: 100%;
}

#dimmer {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgb(27, 27, 27);
    z-index: 0;
}

#editing-nav {
    color: black;
    display: inline-flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    list-style-type: none;
    text-align: center;
    margin-top: 15px;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    padding: 0px 4px;

}

#editing-nav > li {
    height: 100%;
    /* padding: 0 2%; */
    width: 20%;
}

/* #editing-nav > li {
    width: 20%;
    height: 100%;
} */

#editing-nav > #who-nav {
    /* width: 12%; */
    /* margin-left: 3%; */
}

#editing-nav > #intersection-nav {
    width: 26%;
}

#role-nav, #source-nav {
    width: 17%;
}

#skills-nav-top * {
    cursor: default;
}

#editing-nav a {
    position: relative;
    text-decoration: none;
    color: rgb(126, 126, 126);
    width: 100%;
    height: 100%;
    display: inline-block;
    cursor: pointer;
}

.skill-nav {
    color: rgb(175, 175, 175);
}

#editing-nav a:hover {
    text-decoration: underline;
}

#skills-page #Master-box {
    margin-bottom: 60px;
}

#imgBo {
    transform: rotate(90deg);
}

.choice-page {
    position: relative;
    z-index: 0;
    /* text-align: center; */
    /* margin: 0 auto; */
}

.roll-source-edit-grid {
    /* display: inline-grid; */
    /* grid-template-columns: 1fr 1fr 1fr; */
    /* grid-gap: 2%; */
    /* margin: 1%; */
    /* width: 100%; */
}

.choice-header-box {
    /* margin: 20px; */
    text-align: center;
    color: white;
    
}

.edit-element-list {
    color: burlywood;
}

.edit-skill-description-part {
    margin: 7px 0;
}

.choice-box {
    background-color: rgba(255, 255, 255, 0.151);
    color: white;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    max-width: 310px;
    margin: 0 auto;
    margin-top: 12px;
    height: 100%;
}

.choice-box:hover {
    background-color: rgba(255, 255, 255, 0.26);
    cursor: pointer;
}

.choice-box:active {
    background-color: rgba(230, 230, 230, 0.089);
}

.choice-box .popup, .choice-box .popup:hover, .choice-box .popup:active {
    cursor: default;
}

.edit-section-title {
    color: white;
    text-align: center;
    font-size: 40px;
    padding: 10px;
    padding-bottom: 0;
}

.next-button, #character-view-button {
    background-color: rgb(157, 199, 146);
    padding: 10px;
    margin: 10px 18px;
    border-radius: 3px;
    text-align: center;
    text-decoration: none;
    /* position: sticky;
    top: 0; */
}

.next-button:hover, #character-view-button:hover {
    background-color: rgb(172, 216, 161);
}

.next-button:active, #character-view-button:active {
    background-color: rgb(224, 241, 220);
}

#create-pdf-button {
    background-color: white;
    border-radius: 3px;
    margin: 10px 18px;
    text-align: center;
    height: 50px;
    line-height: 50px;
}

a {
    text-decoration: none;
    color:black;
}

#skills-nav-top {
    display: inline-flex;
    position: relative;
    width: 90%;
    max-width: 550px;
    text-align: center;
    /* margin-top: 15px; */
    height: 35px;
    line-height: 34px;
    vertical-align: middle;
    /* padding: 0px 4px; */
    margin: 10px 5% 2%;
    background-color: rgb(54, 54, 54);
    border: solid 1px lightgray;
}

#skills-nav-top *:hover {
    cursor:pointer;
    background-color: rgb(92, 92, 92);
    border-bottom: solid 1px lightgray;
    height: 34px;
}

#finish-nav {
    border: solid 1px rgb(157, 199, 146);
}

#skills-nav-top *:active {
    background-color: rgb(54,54,54);
}

#skills-nav-top > * {
    width: 50%;
    /* color: rgb(199, 199, 199); */
}

.background-header {
    text-align: center;
    color: white;
}

#background-header-title {
    padding-top: 10px;
}

p.background-header {
    font-size: 15px;
}

.description-header {
    color: white;
    font-size: 1.1em;
    margin-left: 3%;
    margin-top: 20px;
}

p.background-header {
    margin-bottom: 5px;
}

.description-box {
    padding: 5px;
    line-height: 1.3em;
    font-size: 1em;
    height: 100px;
    width: 94%;
    max-width: 600px;
    margin: 3%;
    margin-top: 1%;
    background-color: rgb(238, 238, 238);
}

#editing-nav .highlight-nav {
    color: white;
    font-weight: bold;
    /* z-index: 30; */
}

#editing-nav .highlight-nav:hover {
    text-decoration: none;
    cursor: default ;
}

#editing-nav .highlight-nav:hover {
    cursor: default;
}

#skills-nav-top .highlight-skill-tab div {
    background-color: lightgray;
    color: black;
}

#skills-nav-top .highlight-skill-tab:hover, #skills-nav-top .highlight-skill-tab > *:hover {
    cursor: default;
    background-color: lightgray;
}

#skill-numbers {
    /* z-index: 100; */
    text-align: right;
    color: white;
    margin: 3%;
}

#choose-skills {
    color: white;
    /* margin: 5%;
    text-align: center; */
}

#skill-numbers-header {
    text-align: left;
}

.next-button {
    /* position: fixed;
    bottom: 5px;
    width: 324px; */
    /* margin: 0 auto; */
    /* text-align: center; */
    /* margin: 0 auto; */
}

#back-pane {
    /* display: none; */
}

.next-button-link {
    position: fixed;
    bottom: 5px;
    width: 100%;
    /* left: 25%; */
    max-width: 600px;
    /* margin: 0 auto; */
    /* text-align: center; */
    /* width: 360px; */
    /* margin: 0 auto; */
    left: 50%;
    transform: translateX(-50%);
}

#skills-page {
    margin: 20px 15px;
}

#skills-page .skillbox {
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 8px ;
    max-width: 650px;
    width: 100%;
    /* background-color: rgba(255, 255, 255, 0.13); */
}

#skills-page .skill-top {
    background-color: rgba(255, 255, 255, 0.13);
}

#skills-page .skill-top.gray {
    background-color: rgb(85, 85, 85);
    box-shadow: 5px 5px 5px black;
}

.open-box .skill-top:hover {
    /* background-color: rgb(114,114,114); */

}

.skillbox .skill-top:active {
    background-color: rgb(82, 82, 82);
}

.open-box .skill-top {
    border: solid 2px rgba(26, 26, 26, 0);
}

.skill-top {
    border: solid 2px rgb(26, 26, 26);
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    vertical-align: middle;
    height: 61px;
    padding: 10px;
    cursor: pointer;
}

.skill-top > * {
    /* display: flex; */
    align-items: center;
    vertical-align: middle;
    height: 100%;
    /* width: 100%; */
}

.skill-top .plus-sign, .skill-top .minus-sign  {
    display: flex;
}

#skills-page .skill-top:hover {
    border: solid 2px rgb(201, 201, 201);
}

#editing-main .skill-box-header-left {
    text-align: left;
    width: 250px;
    vertical-align: middle;
    /* align-content: center; */
    height: 100%;
    /* display: table-column; */
    /* display: table; */
}

#editing-main .skill-box-header-left > * {
    width: 100%;
    height: 49%;
    /* display: table-cell; */
    vertical-align: middle;
    /* display: flex; */
}

#editing-main .tracking-skill-numbers {
    font-weight: bold;
}

#editing-main .tracking-skill-choices {
    /* text-align: left; */
} 

#editing-main .skill-list {
    text-align: left;
}

#editing-main .skill-list .info {
    display: inline-grid;
    grid-template-columns: 15px auto;
    width: 100%;
    cursor: pointer;
}

.open-box .skill-top {
    top: 125px;
    position: sticky;
}

.skill-box-header-left {
    display: table;
}

.skill-top-table {
    display: table-cell;
    vertical-align: middle;
}

.item-name {
    font-size: 1.3em;
    margin-bottom: 8px;
}

.action-status {
    color: rgb(255, 103, 2);
}

.action-passive {
    color: rgba(182, 182, 182, 0.925);
}

#editing-main .editing-item-content {
    padding: 6px 6px 14px;
    margin: 7px 0px 0px;
    text-align: left;
    border-left: solid 4px rgba(255, 255, 255, 0);
    border-right: solid 2px rgb(124,124,124);
    border-bottom: solid 2px rgb(124, 124, 124);
    /* background-image: linear-gradient( rgba(70, 70, 70, 0),rgb(70, 70, 70) ); */

    /* vertical-align: middle; */
}

#editing-main .editing-item-content:hover, #editing-main .editing-item-content *:hover  {
    /* cursor: default; */
}

#editing-main .editing-item-content:hover {
    /* background-image: linear-gradient( rgba(70, 70, 70, 0),rgba(70, 70, 70, 0.315) ); */
    /* border-left: solid 4px white; */
    /* background-color: rgba(255, 255, 255, 0.103); */
    border-bottom: solid 2px white;
    border-right: solid 2px white;
    /* box-shadow: 5px 5px 5px black; */

}

.edit-skills-checkbox {
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
    border: none;
    background-color: none;
    width: 10px;
    transition: 0.2s;
    position: relative;
    z-index: -1;
    margin: 0 auto;
    margin-top: 4px;
    margin-bottom: 3px;
}

.edit-skills-checkbox:checked {
    background-color: rgb(233, 164, 74);
    /* margin-bottom: 7px; */
}

input:focus {
    outline: none;
}

.edit-skills-checkbox:focus {
    outline: none;
}

#new-hero-name, #new-hero-title {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    text-align: center;
}

#new-hero-name {
    font-size: 2em;
    padding: 0;
    margin: 20px 0 0;
    /* text-decoration: underline; */
}

#new-hero-title {
    font-size: 1.3em;
    padding: 0;
    margin: 0;
}

.editing-pane-section h2, .editing-pane-section h3 {
/* #label-input-title h2, #label-input-name h2 { */
    font-size: 22px;
}

#label-input-title h2 {
    margin-top: 28px;
    /* font-size: 28px; */
}

#label-input-name {
    width: 340px;
}

#background-feature {
    /* height: 215px; */
}

#background-features-dropdown {
    width: 200px;
    height: 30px;
    font-size: 18px;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    outline: none;
    margin: 15px auto 10px;
    text-align: center;
    display: block;
    /* padding: 10px; */
}

#background-features-dropdown option {
    background-color: rgba(0, 0, 0, 0.568);
    font-size: 15px;
    /* height: 40px; */
}

#background-features-dropdown option:hover {
    background-color: rgba(53, 53, 53, 0.568);
}

#background-feature-description {
    padding: 5px 5px 10px;
    max-width: 350px;
    text-align: center;
    margin: auto;
}

.who-input-box {
    margin: 0 auto;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 22px;
    font-size: 22px;
    text-align: center;
    background-color: rgb(238, 238, 238);
    border-right: none;
    border-left: none;
    border-top: black;
    border-bottom: black;
    padding: 3px;
    /* cursor: pointer; */
    /* text-align: center; */
}

#optional {
    margin-top: -4px;
}

#footer-hyphen {
    margin-top: 50px;
}

.tiny-description {
    font-size: .75em;
    /* font-style: italic; */
}

.choice-page > .choice-header-box > h2 {
    margin-top: 3.5%;
}

#interior-level-box {
    /* margin: 15px; */
}

.editing-pane-section {
    background-color: rgba(255, 255, 255, 0.116);
    padding: 20px;
    margin: 20px 15px;
    border-radius: 10px;
    color: white;
}

.editing-pane-section h2 {
    text-align: center;
}

#myRange {
    /* width: 180px; */
}

#level-input-line {
    /* width: 500px; */
    /* text-align: center; */
    /* vertical-align: top; */
    /* display: inline-block; */
    grid-template-columns: 50px 210px;
    height: 2em;
    /* line-height: 2em; */
    /* vertical-align: middle; */
    justify-content: center;
    align-items: center;
    /* margin */
    margin: 0 auto;
    /* template */
    /* text-align: center; */
}

#level-input-line > * {
    vertical-align: middle;
    display: inline-block;
}

.level-button {
    color: rgb(59, 59, 59);
    background-color: rgba(255, 255, 255, 0.76);
    border-radius: 4px;
    line-height: 40px;
    width: 30px;
    height: 40px;
    margin-right: 10px;
    margin-left: 10px;
    font-weight: bolder;
    font-size: 2em;
}

.level-button:hover {
    background-color: white;
    cursor: pointer;
    -webkit-user-select: none; /* Chrome/Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */

    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
}

.level-button:active {
    background-color: rgba(255, 255, 255, 0.363);
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#submit-level-button {
    width: 129px;
    /* left: 50%; */
    font-size: 18px;
    border: none;
    margin-top: 28px;
    text-align: center; 
    
}

#level-display {
    font-size: 2.4em;
    margin: 12px 10px 5px;
}

/* The slider itself */
.slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    /* width: 70%; Full-width */
    height: 10px; /* Specified height */
    background: #808080; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    position: relative;
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 35px; /* Set a specific slider handle width */
    height: 35px; /* Slider handle height */
    background: white; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%; 
}

.slider::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #4CAF50; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.role-icon-img {
    max-width: 100px;
    max-height: 120px;
    filter: brightness(0%);
    margin: 0 auto;
    padding-top: 10px;
}

.edit-tagline {
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    /* text-align: center; */
}

#Shade-img {
    max-width: 130px;
    width: 129.2px;
    height: 110px;
}

.chosen-rolesource {
    background-color: rgba(40, 214, 98, 0.39);
}

.chosen-rolesource:hover {
    background-color: rgba(125, 255, 168, 0.39);
    
}

.chosen-rolesource:active {
    background-color: rgba(0, 92, 31, 0.39);
}

#finish-page {
    color: white;
    text-align: center;
}

#finish-page p {
    padding: 30px;
}

#create-pdf-button {
    color: black;
}

.no-rolesource-notice {
    padding: 27px;
}

@media all and (min-width: 603px) { 

    #editing-nav > #who-nav, #editing-nav > #intersection-nav {
        width: 20%;
        margin-left: 0;
    }

    .next-button-link {
        /* bottom: 15px; */
    }
    
    .description-header {
        font-size: 1.5em;
        margin-top: 25px;
    }

    .tiny-description {
        font-size: .8em;
    }

    .name-page {
        width: 600px;
        margin: 0 auto;
    }

    .roll-source-edit-grid {
        margin: 20px 30px 0px;
        display: grid;
        /* grid-template-columns: repeat( 3, minmax(0, 1fr) ); */
        grid-template-columns: repeat( 4, 1fr );
        grid-gap: 12px;
    }

    .roll-source-edit-grid > * {
        max-width: 100%;
        margin-top: 0px;
        /* grid-column: span 1; */
        grid-column: span 2;
    }
    
    .choice-box {
        margin-top: 0px;
        max-width: 400px;
    }

    .roll-source-edit-grid > *:nth-last-child(1):nth-child(odd) {
        /* grid-column: 2 / span 1; */
        grid-column: 2 / span 2;
    }

    .edit-section-title {
        font-size: 33px;
    }
}

@media all and (min-width: 800px) { 

    .roll-source-edit-grid.role {
        grid-template-columns: repeat( 3, minmax(0, 1fr) );
    }

    .roll-source-edit-grid.role > * {
        grid-column: span 1;
    }

    .roll-source-edit-grid.role > *:nth-last-child(1):nth-child(odd) {
        grid-column: 2 / span 1;
    }
    .edit-section-title {
        font-size: 30.5px;
        padding-right: 5px;
        padding-left: 5px;
    }
}