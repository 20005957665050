@font-face {
  font-family: "Netherlands";
  src: url('./ImageStore/rijksoverheidserif-regular.ttf')
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: "Netherlands"; */
  font-family: 'Josefin Sans', sans-serif;
}

#game-title {
  /* font-family: "Netherlands"; */
}

html {
  height: 100%; 
  background-color: rgb(54, 54, 54);
}

body {
  height: 100%;
  width: 100%;
  /* background-color: white; */
  /* height: 100%; */
  /* max-height: 100%; */
}

#root {
  height: 100%;
}

#page {
  width: 100%;
  /* display: table; */
  /* grid-row-gap: 0px; */
  height: 100%;
  /* background-color: rgb(71, 71, 71); */
  /* background-color: white; */
}

#page > #back-pane {
  height: 100vh;
  /* width: 100vw; */
  top: 0;
  /* width: 800px; */
  overflow: hidden;
  /* background-color: white; */
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

#main-container {
  /* background-color: white; */
  /* background: linear-gradient(to right bottom, rgb(54,54,54) 50%, rgb(71, 71, 71) 50%); */
  /* height: 100%; */
}

main, #fixed-top {
  /* display: table-row; */
  width: 100%;
}
/* 
#background-top-bar + main {
  height: 100vh;
} */

*:not(#dropdown *){
  cursor: default;
}

.heavy {
  font-weight: bold;
  letter-spacing: .05em;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline ;
}

.red {
  color: rgb(202, 5, 5);
}

#page > * {
  max-width: 800px;
  margin: 0 auto;
  /* background-color: white; */
}

main {
  position: relative;
  z-index: 0;
  /* height: -webkit-calc(100% - 117px); */
  /* height: -moz-calc(100% - 117px); */
  height: calc(100% - 117px);
  /* background: linear-gradient(to right bottom, rgb(54,54,54) 50%, rgb(71, 71, 71) 50%); */
  /* height: 100%; */
  /* padding-top: 30%; */
}

#main-container {
  max-width: 800px;
  margin: 0 auto;
  height: 100%;
}

#main-container > *{
  /* background-color: white; */
  /* height: 100%; */
}

#page > .filler-white {
  background-color: white;
  height: 24px;
  /* position: absolute; */
  width: 100%;
  z-index: -1;
  margin-bottom: -24px;
  /* left: 50%; */
  /* margin: 0 auto; */
  /* margin:0 auto; */
  /* text-align: center; */
}

.hidden {
  display: none !important;
}

#fixed-top {
  position: fixed;
  width: 100%;
  top: 0;
  max-width: none;
  z-index: 1;
}

#background-top-bar {
  /* position: fixed; */
  width: 100%;
  z-index: -1;
}

#background-nav-bar {
  background-color: rgb(44,44,44);
  height: 45px;
  z-index: 2;
}

#background-character-bar {
  background-color: rgb(88,129,100);
  height: 72px;
}

#bottom-pane {
  text-align: center;
  /* bottom: 0px; */
  padding: 25px 0 17px;
}

.aware {
  z-index: 999;
  position: relative;
}

button {
  outline: none;
}

.highlight-nav {
  pointer-events: none;
}