
.morph-choice {
    margin: 10px;
    text-align: center;
}

.morph-choice h4 {
    font-size: 1.2em;
}

.beast-radio {
    display: none;
}

.morph-choice h3 {
    margin: 3px;
}

.morph-choice p {
    margin: 8px;
}

.morph-options > * {
    /* border: solid 2px white; */
}

p.beast-name-inputs {
    margin-top: 15px;
}

input.beast-name-inputs {
    padding: 5px 0px;
    text-align: center;
    font-size: 20px;
    width: 120px;
    color: white;
    background-color: rgba(255, 255, 255, 0.11);
}

input.beast-name-inputs:focus {
    color: black;
    background-color: rgb(185, 185, 185);
}

input.beast-name-inputs::placeholder {
    color: white;
}

@media all and (min-width: 640px) { 


}