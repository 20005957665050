
main.popup-active *:not(.popup) {
    pointer-events: none;
}

.popup {
    /* display: none; */
    position: fixed;
    z-index: 1000;
    /* background-color: green; */
    padding-top: 100px; /* Location of the box */
    /* padding-bottom: 200px; */
    left: 0;
    top: 40px;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.4);
    /* top: 30%; */
    /* left: 20%; */
    /* width: 80%; */
}

.popup-inside {
    margin: auto;
    color: white;
    /* text-align: center; */
    /* background-color: rgba(0, 17, 8, 0.788); */
    background-color: rgb(14, 14, 14);
    /* padding: 10px; */
    border: 1px solid #888;
    width: 92%;
    max-width: 500px;
    position: relative;
}

.popup-x {
    color: rgba(255, 255, 255, 0.829);
    cursor: pointer;
    padding: 10px;
    font-size: 2.4em;
    /* border: solid 1px white; */
    position: absolute;
    top: -10px;
    right: 0px;
}

.popup-x:hover {
    color: white;
}

.popup-x:active {
    color: rgb(153, 153, 153);
}

.popup h1 {
    text-align: center;
    margin: 10px;
}

.scrollable-popup-portion {
    overflow-y: scroll;
    height: 60vh;
}