h1 {
    font-size: 2em;
    font-family: 'Righteous', cursive;
    font-weight: lighter;
    padding: 0 8px;
}
  
nav {
    display: flex;
    justify-content: space-between;
    height: 45px;
    line-height: 45px;
    /* padding: 0 12px 0 8px; */
    background-color: rgb(36, 36, 36);
    color: white;
    position: relative;
    z-index: 1000;
}

nav > * {
    z-index: 12;
}

nav * {
    margin: 0;
}

/* #plus, #minus {
    border-radius: 6px;
    line-height: 30px;
    margin: 7.5px 0;
    height: 30px;
    width: 30px;
    padding: 0 9.5px;
} */

/* #plus:hover, #minus:hover {
    background-color: rgb(31, 31, 31);
    cursor: pointer;
} */

/* #plus:active, #minus:hover {
    background-color: rgb(49, 49, 49);
    cursor: pointer;
} */

#dropdown:hover:after, #dropdown:active,
#dropdown *:hover, #dropdown *:active {
    cursor: pointer;    
} 

#dropdown {
    z-index: 1000;
}

#game-title {
    padding-bottom: 10px;
}

#menu {
    /* display: none; */
    position: absolute;
    right: 4px;
    background-color: #5a5a5a;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    /* padding: 12px 16px; */
    z-index: 1000;
    list-style: none;
    margin-top: -23px;
    height: 230px;
    -moz-transition: height .3s;
    -ms-transition: height .3s;
    -o-transition: height .3s;
    -webkit-transition: height .3s;
    transition: height .3s;
    /* border-right: solid 1px white; */
}

#menu li {
    z-index: 10;
    /* border-right: solid 2px #5a5a5a; */
    /* border-left: solid 2px #5a5a5a; */
    border-bottom: solid 1px #444444;
    /* border-top: solid 2px #5a5a5a; */
    width: 100%;
    padding: 0px 10px;
    color: rgb(230, 230, 230);
}

#menu li:hover {
    border-color: black;
    color: white;
    background-color: #424242;
    cursor: pointer;
}

#menu li:active {
    background-color: #6b6b6b;
}

.menu-box {
    display: inline-block;
    cursor: pointer;
    background-color: rgba(44, 44, 44, 0);
    padding: 2px 4px;
    /* border: solid 1px #5a5a5a; */
    border-radius: 4px;
    margin: 5.5px;
    transition: all 1s;
}

.menu-box:hover {
    background-color: rgba(0, 0, 0, 0.3)
}
  
.bar1, .bar2, .bar3 {
    width: 27px;
    height: 2px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
}
  
.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-5px, -5px);
}

.menu-box.change {
    background-color: #5a5a5a;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
}

.menu-box.change:hover {
    background-color: rgba(255, 255, 255, 0.384);
}

#dropdown .retracted {
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
    height: 0;
    overflow: hidden;
}

@media all and (min-width: 800px) { 

    nav {
        /* box-shadow: 0px 0px 2px 0px #000000; */
    }

}
